<template>
  <app-modal
    :title="$t('modal.edit_images')"
    :show-close-button="!isLoading"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <template v-if="allowGalleryCreation">
          <button
              v-if="showGallery"
              @click="toggleGallery"
              type="button"
              class="btn btn-danger"
          >
              {{ $t('modal.cancel_create_gallery') }}
          </button>
          <button
              v-else
              @click="toggleGallery"
              type="button"
              class="btn btn-info"
          >
              {{ $t('modal.create_gallery') }}
          </button>
      </template>
      <button
        @click="onConfirmModal"
        type="button"
        :class="`btn btn-green ${confirmButtonDisabled ? 'btn-green--disabled' : ''}`"
        data-dismiss="modal"
        data-test="media_btn_onConfirmModal"
        :disabled="confirmButtonDisabled"
      >
        <i class="fa fa-check"></i> {{ insertLabel }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <slot name="topOfBody" />
      <div v-if="failedDamMedia.length > 0" class="failed-files">
        <app-checkbox
          v-model="tryCreateDamMediaAgain"
          :label="$t('media.try_upload_again')"
          id="tryCreateMediaAgain"
        />
        <div>
          <span class="failed-files__label">
            {{ $t('media.failed_uploaded_files') }}
          </span>
          {{ failedDamMedia.map(media => media.fileName ? media.fileName : media.imageCaption).join(', ') }}
        </div>
      </div>
      <div class="m-b-40" v-if="isLoading">
        <h5 class="text-center">{{ loadingProgressText }}</h5>
        <Progressbar :progress="loadingProgress" :animated="false" />
      </div>
      <div class="row gallery" v-if="showGallery">
        <div class="col-lg-9">
          <app-input
            v-model="gallery.title"
            :error="gallery.title.length === 0"
            id="gallery_title"
            :label="$t('modal.title')"
            required
            show-counter
          ></app-input>
          <app-textarea
            v-model="gallery.description"
            disable-form-group
            id="gallery_description"
            :label="$t('modal.description')"
            :rows="5"
            show-counter
            class="form-group"
          ></app-textarea>
          <app-select
            v-model="gallery.site"
            :options="sites"
            id="gallery_site"
            :label="$t('modal.site')"
            required
            :disabled="'disabled'"
          ></app-select>
          <div class="row">
            <div class="col-lg-5">
              <label>{{ $t('modal.tags') }}</label>
              <span class="required">*</span>
            </div>
            <div class="col-lg-7">
              <app-create-tag-button
                v-if="searchedTag"
                :searchedTag="searchedTag"
                @set-tag="addTag"
              ></app-create-tag-button>
            </div>
          </div>
          <app-multi-select
            v-model="gallery.expanded.tags"
            :options="tags"
            label="title"
            track-by="id"
            :preselect-first="true"
            :internal-search="false"
            :options-limit="300"
            :limit="10"
            :max-height="600"
            :show-no-results="false"
            open-direction="bottom"
            @search-change="findTag"
            id="gallery_tags"
            class="form-group"
          ></app-multi-select>
        </div>
        <div class="col-lg-3">
          <app-checkbox
            v-model="gallery.public"
            :label="$t('modal.public')"
            id="gallery_public"
            class="m-t-20"
            :tooltip="$t('dam.public_info')"
          ></app-checkbox>
        </div>
      </div>
      <div v-if="forceSelectingSite" class="row">
        <div class="col-4">
          <app-select
            v-model="mandatorySite"
            :options="sites"
            id="mandatory_site"
            :label="$t('modal.site')"
            required
          />
        </div>
      </div>
      <div v-if="!forceSelectingSite || mandatorySite" class="row" >
        <div :class="{'col-lg-9': !singleMediaSelected, 'col-lg-12': singleMediaSelected}">
          <div v-for="(media, index) in selectedMedia" :key="`item-${media.damMediaEmbed.damId}`" class="m-b-20">
            <div class="row">
              <div :class="{'col-lg-7': !singleMediaSelected, 'col-lg-8': singleMediaSelected}">
                <MediaEditModalCropper
                  :media="media"
                  :single-media-selected="singleMediaSelected"
                  :available-crops="availableCrops"
                  @loaded="isLoading = false"
                />
              </div>
              <div :class="{'col-lg-5': !singleMediaSelected, 'col-lg-4': singleMediaSelected}">
                <small
                  v-if="media.imageCaption && media.imageCaption.length > 500"
                  class="form-control-feedback text-warning float-right"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ $t('media.title_is_too_long') }}
                </small>
                <app-textarea
                  v-model.trim="media.imageCaption"
                  :label="$t('dam.description')"
                  :id="`media-caption-${index}`"
                  :key="`media-caption-${index}-${forceInputsUpdate}`"
                  :error="!media.imageCaption"
                  required
                >
                  <app-tooltip
                    :title="$t('dam.headline_seo_info')"
                    icon="fab fa-google"
                    customInfoClass="seo"
                  ></app-tooltip>
                </app-textarea>
                <app-input
                  v-model.trim="media.imageAltText"
                  :label="$t('dam.imageAltText')"
                  :id="`media-imageAltText-${index}`"
                  :key="`media-imageAltText-${index}-${forceInputsUpdate}`"
                  :error="!media.imageAltText && vlm"
                  :required="vlm"
                />
                <AuthorSearchSelect
                  :value="defaultInputValueOfAuthor ? defaultInputValueOfAuthor : media.imageAttribution"
                  @input="author => media.imageAttribution = author"
                  :id="`media-author-${index}`"
                  :key="`media-author-${index}-${forceInputsUpdate}`"
                  :error="!defaultInputValueOfAuthor && !media.imageAttribution"
                  :disabled="Boolean(defaultInputValueOfAuthor)"
                />
                <SourceSearchSelect
                  v-model="media.imageSource"
                  :id="`media-source-${index}`"
                  :key="`media-source-${index}-${forceInputsUpdate}`"
                />
                <app-input
                  v-if="media.alsoUpdateDamAssetMetadata"
                  v-model.trim="media.keywords"
                  :label="$t('modal.keywords')"
                  :id="`media-keywords-${index}`"
                  :key="`media-keywords-${index}-${forceInputsUpdate}`"
                />
                <app-checkbox
                  v-if="!vlm"
                  v-model="media.alsoUpdateDamAssetMetadata"
                  :label="$t('dam.also_update_dam_asset_metadata')"
                  :id="'media-alsoUpdateDamAssetMetadata-' + index"
                  @input="oneAlsoUpdateDamAssetMetadataChanged"
                  disableFormGroup
                />
                <app-checkbox
                  v-model="media.hideCaption"
                  :label="$t('modal.hidden_caption_on_web')"
                  :id="'media-hidden-caption-on-web-' + index"
                  disableFormGroup
                >
                </app-checkbox>
                <app-checkbox
                  v-show="isGallery === false"
                  v-model="media.exportWoodWing"
                  :label="$t('modal.export_wood_wing')"
                  :id="'media-export-wood-wing-on-web-' + index"
                  disableFormGroup
                >
                </app-checkbox>
                <app-checkbox
                  v-model="media.sensitiveContent"
                  :label="$t('dam.sensitive_content')"
                  :id="'media-sensitive_content-' + index"
                  disableFormGroup
                >
                </app-checkbox>
                <i
                  v-if="!singleMediaSelected"
                  class="fa fa-trash del media-delete media-edit-modal__remove-selected-media"
                  :title="$t('media.button_remove')"
                  data-test="media_removeMedia"
                  @click="removeSelectedMedia(index)"
                >
                </i>
              </div>
            </div>
            <hr v-if="index+1 < selectedMedia.length">
          </div>
        </div>
        <div class="col-lg-3" v-if="!singleMediaSelected">
          <div>
            <strong>{{ $t('media.predetermined_values') }}</strong>
            <small
              v-if="defaultImageCaption.length > 500"
              class="form-control-feedback text-warning float-right"
            >
              <i class="fa fa-exclamation-triangle"></i>
              {{ $t('media.title_is_too_long') }}
            </small>
            <app-textarea
              v-model.trim="defaultImageCaption"
              :label="$t('dam.description')"
              id="media-caption-default"
            >
              <app-tooltip
                :title="$t('dam.headline_seo_info')"
                icon="fab fa-google"
                customInfoClass="seo"
              ></app-tooltip>
            </app-textarea>
            <app-input
              v-model.trim="defaultImageAltText"
              :label="$t('dam.imageAltText')"
              id="media-imageAltText-default"
            />
            <AuthorSearchSelect
              v-model.trim="defaultImageAttribution"
              :required="false"
              id="media-author-default"
            />
            <app-textarea
              v-if="alsoUpdateDamAssetMetadata"
              v-model.trim="defaultImageKeywords"
              :label="$t('modal.keywords')"
              id="media-keywords-default"
            >
            </app-textarea>
            <app-checkbox
              v-if="!vlm"
              v-model="alsoUpdateDamAssetMetadata"
              :label="$t('dam.also_update_dam_asset_metadata')"
              id="alsoUpdateDamAssetMetadata"
              class="m-b-10"
              @input="allAlsoUpdateDamAssetMetadataChanged"
              disableFormGroup
            />
            <button
              class="btn btn-danger"
              @click="removeTitles"
            >
              {{ $t('media.remove_all_titles') }}
            </button>
            <br>
            <button
              class="btn btn-info m-t-10"
              @click="setPredeterminedTitles"
            >
              {{ $t('media.set_predetermined_titles') }}
            </button>
          </div>
          <div class="checkbox checkbox-success m-t-20 m-b-20"
               v-if="showPageBreaksCheckbox"
          >
            <input
              type="checkbox"
              id="addPageBreaks"
              class="filled-in"
              v-model="pageBreaks"
            >
            <label class="custom-control custom-checkbox" for="addPageBreaks">
              <small>{{ $t('media.add_pagebreak_text') }}</small>
            </label>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Checkbox from '../form/Checkbox'
import CoreApi from '../../api/core'
import NotifyService from '../../services/NotifyService'
import MediaMixin from '../mixins/Media'
import CropMixin from '../mixins/Crop'
import Modal from './Modal'
import Tooltip from '../tooltip/Tooltip'
import Select from '../form/select/Select'
import MultiSelect from '../form/select/MultiSelect'
import CreateTagButton from '../article/ArticleCreateTagButton'
import Gallery from '../../model/GalleryModel'
import { mapGetters, mapState } from 'vuex'
import Progressbar from '@/components/Progressbar'
import MediaEditModalCropper from '@/components/shared/mediaEditModal/MediaEditModalCropper'
import {
  MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK,
  MEDIA_USAGE_TYPE_ARTICLE_HERO,
  MEDIA_USAGE_TYPE_ARTICLE_LISTING,
  MEDIA_USAGE_TYPE_ARTICLE_GALLERY,
  MEDIA_USAGE_TYPE_GALLERY,
  MEDIA_USAGE_TYPE_DEFAULT
} from '@/model/ValueObject/MediaUsageTypes'
import ErrorHandlingService from '@/services/ErrorHandlingService'
import MediaService from '@/services/media/MediaService'
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import SourceSearchSelect from '@/components/author/SourceSearchSelect'
import { MODULE_ARTICLE } from '@/model/ValueObject/UserPermissionModules'
import { PERMISSION_CREATE } from '@/model/ValueObject/UserPermissions'

require('tracking')
require('tracking/build/data/face-min')

export default {
  name: 'MediaEditModal',
  mixins: [MediaMixin, CropMixin],
  props: {
    selectedMedia: {
      type: Array,
      required: true
    },
    showPageBreaksCheckbox: {
      type: Boolean,
      default: false
    },
    isGallery: {
      type: Boolean,
      default: false
    },
    site: {
      type: [Number, Object],
      default: null
    },
    /**
     * See MediaUsageTypes.js
     */
    mediaUsageType: {
      type: [String, Object],
      default: null
    },
    allowGalleryCreation: {
      type: Boolean,
      default: false
    },
    insertButtonLabel: {
      type: String,
      default: ''
    },
    defaultInputValueOfAuthor: {
      type: String,
      default: ''
    },
    forceSelectingSite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      caption: [],
      createdDamMedia: [],
      failedDamMedia: [],
      tryCreateDamMediaAgain: false,
      pageBreaks: false,
      defaultImageCaption: '',
      defaultImageAltText: '',
      defaultImageAttribution: '',
      defaultImageKeywords: '',
      isLoading: true,
      loadingImageProcess: 0,
      loadingImageCount: 0,
      loadingProcessInterval: null,
      showGallery: false,
      gallery: { ...Gallery, site: this.site },
      searchedTag: '',
      tags: [],
      alsoUpdateDamAssetMetadata: false,
      forceInputsUpdate: 0,
      mandatorySite: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters(['vlm']),
    confirmButtonDisabled () {
      return this.isLoading || !this.isValid || (this.forceSelectingSite && !this.mandatorySite)
    },
    isValid () {
      if (this.defaultImageCaption && this.defaultImageAttribution && this.defaultImageAltText) {
        return true
      }
      let isValid = true
      for (const media of this.selectedMedia) {
        if (!media.imageCaption && !this.defaultImageCaption) {
          isValid = false
        }
        if (this.vlm && !media.imageAltText && !this.defaultImageAltText) {
          isValid = false
        }
        if (!this.defaultInputValueOfAuthor && !media.imageAttribution && !this.defaultImageAttribution) {
          isValid = false
        }
      }
      return isValid
    },
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_ARTICLE, PERMISSION_CREATE)
    },
    availableCrops () {
      const site = this.mandatorySite ?? this.site
      return this.$store.getters['media/getAvailableCrops'](site, this.mediaUsageType)
    },
    loadingProgress () {
      return Math.min(this.loadingImageProcess / this.loadingImageCount * 100, 99)
    },
    loadingProgressText () {
      return `${this.$t('modal.uploading')} ${Math.round(this.loadingProgress)}%`
    },
    singleMediaSelected () {
      return this.selectedMedia.length === 1
    },
    insertLabel () {
      if (this.tryCreateDamMediaAgain) {
        return this.$t('media.try_again')
      }
      if (this.showGallery) {
        return this.$t('dam.create_gallery_insert_into_article')
      }
      if (this.insertButtonLabel) {
        return this.insertButtonLabel
      }
      switch (this.mediaUsageType) {
        case MEDIA_USAGE_TYPE_ARTICLE_HERO:
          return this.$t('dam.insert_into_article_hero')
        case MEDIA_USAGE_TYPE_ARTICLE_LISTING:
          return this.$t('dam.insert_into_article_listing')
        case MEDIA_USAGE_TYPE_ARTICLE_BODY_BLOCK:
          return this.$t('dam.insert_into_article_body_block')
        case MEDIA_USAGE_TYPE_GALLERY:
        case MEDIA_USAGE_TYPE_ARTICLE_GALLERY:
          return this.$t('dam.insert_into_gallery')
        default:
          return this.$t('modal.insert')
      }
    }
  },
  components: {
    SourceSearchSelect,
    AuthorSearchSelect,
    MediaEditModalCropper,
    Progressbar,
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appCheckbox: Checkbox,
    appTooltip: Tooltip,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appCreateTagButton: CreateTagButton
  },
  methods: {
    uploadStarted () {
      this.isLoading = true
      this.loadingImageCount = this.getMediasToBeCreated().length
      this.loadingImageProcess = 0
      this.loadingProcessInterval = setInterval(() => {
        if ((this.loadingImageCount <= MediaService.DAM_UPLOAD_CHUNK_SIZE) ||
          (this.loadingImageProcess < Math.ceil(this.loadingImageProcess) - 0.05) ||
          (this.loadingImageProcess === Math.ceil(this.loadingImageProcess))) {
          this.loadingImageProcess += 0.05
        }
      }, 150)
    },
    uploadFinished () {
      if (this.loadingProcessInterval) {
        clearInterval(this.loadingProcessInterval)
      }
      this.isLoading = false
    },
    setLoading (loading) {
      this.isLoading = loading
    },
    removeSelectedMedia (index) {
      const { damId } = this.selectedMedia[index].damMediaEmbed
      this.$store.commit('dam/removeCropPositionEmbed', damId)
      this.selectedMedia.splice(index, 1)
    },
    allAlsoUpdateDamAssetMetadataChanged () {
      if (!this.alsoUpdateDamAssetMetadata) {
        this.defaultImageKeywords = ''
      }
      this.selectedMedia.forEach(media => {
        media.alsoUpdateDamAssetMetadata = this.alsoUpdateDamAssetMetadata
      })
    },
    oneAlsoUpdateDamAssetMetadataChanged () {
      this.alsoUpdateDamAssetMetadata = this.selectedMedia.every(media => media.alsoUpdateDamAssetMetadata)
      this.forceInputsUpdate += 1
    },
    getFinalInputValues (media) {
      return {
        imageCaption: media.imageCaption || this.defaultImageCaption,
        imageAltText: media.imageAltText || this.defaultImageAltText,
        imageAttribution: this.defaultInputValueOfAuthor || media.imageAttribution || this.defaultImageAttribution,
        imageSource: media.imageSource,
        keywords: media.keywords || this.defaultImageKeywords
      }
    },
    getPayloadForMediaUsage (media) {
      const mediaUsageType = this.showGallery ? MEDIA_USAGE_TYPE_GALLERY : this.mediaUsageType
      const inputValues = this.getFinalInputValues(media)
      delete inputValues.keywords
      const damAssetUuid = media.damMediaEmbed.damId
      const { crops, selectedByAuthorCropName } = this.$store.getters['dam/cropPositionEmbed'][damAssetUuid]
      const mandatoryRatios = this.$store.getters['media/getMandatoryRatios'](mediaUsageType)
      const cropRequests = Object.keys(crops)
        .filter(aspectRatio => mandatoryRatios.includes(aspectRatio) || aspectRatio === selectedByAuthorCropName)
        .map(aspectRatio => {
          const crop = crops[aspectRatio]
          const [ratioX, ratioY] = aspectRatio.split(':')
          return {
            cropCoordinates: {
              leftUpperX: crop.x,
              leftUpperY: crop.y,
              rightBottomX: crop.x + crop.width,
              rightBottomY: crop.y + crop.height
            },
            cropRatioWidth: +ratioX,
            cropRatioHeight: +ratioY,
            selectedByAuthor: selectedByAuthorCropName === aspectRatio
          }
        })
      return {
        damAssetUuid,
        ...inputValues,
        hideCaption: Boolean(media.hideCaption),
        exportWoodWing: Boolean(media.exportWoodWing),
        sensitiveContent: Boolean(media.sensitiveContent),
        showAttribution: true,
        mediaUsageType: mediaUsageType ?? MEDIA_USAGE_TYPE_DEFAULT,
        site: this.mandatorySite ?? this.site,
        cropRequests
      }
    },
    getPayloadForUpdateMetadata (media) {
      const inputValues = this.getFinalInputValues(media)
      const metadataPayload = {
        entityUUid: media.damMediaEmbed.damId,
        description: inputValues.imageCaption,
        imageAltText: inputValues.imageAltText,
        author: inputValues.imageAttribution,
        source: inputValues.imageSource,
        keywords: inputValues.keywords
      }
      if (media.dateTimeOriginal) {
        metadataPayload.dateTimeOriginal = media.dateTimeOriginal
      }
      return metadataPayload
    },
    async updateDamAssetMetadata () {
      let multiMetadata
      if (this.alsoUpdateDamAssetMetadata) {
        multiMetadata = this.selectedMedia.map(this.getPayloadForUpdateMetadata)
      } else {
        multiMetadata = this.selectedMedia
          .filter(media => media.alsoUpdateDamAssetMetadata)
          .map(this.getPayloadForUpdateMetadata)
      }
      if (multiMetadata.length > 0) {
        await this.$store.dispatch('dam/updateMetadataBulkMulti', multiMetadata)
          .then(() => {
            const error = this.$store.getters['dam/error']
            if (error) {
              console.error(error)
              NotifyService.setErrorMessage(error)
            }
          })
      }
    },
    getMediasToBeCreated () {
      if (this.failedDamMedia.length === 0) {
        return this.selectedMedia
      } else {
        if (this.tryCreateDamMediaAgain) {
          return this.failedDamMedia
        } else {
          return [] // user chose to ignore the failed medias
        }
      }
    },
    async createMediaUsageInChunks () {
      this.uploadStarted()
      const medias = this.getMediasToBeCreated()
      this.failedDamMedia = []
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < medias.length; i += chunkSize) {
        const chunkMedias = medias.slice(i, i + chunkSize)
        const promises = chunkMedias.map(this.createMediaUsage)
        await Promise.all(promises)
      }
      this.uploadFinished()
    },
    async onConfirmModal () {
      if (!this.isValid) {
        return
      }
      await this.createMediaUsageInChunks()
      if (this.failedDamMedia.length === 0) {
        await this.updateDamAssetMetadata()
        this.$emit('add-page-breaks', this.pageBreaks)
        if (this.showGallery) {
          try {
            this.gallery.expanded.medias = this.createdDamMedia
            const gallery = await this.$store.dispatch('gallery/create', this.gallery)
            this.$emit('set-gallery-group', gallery.id)
            NotifyService.setSuccessMessage(this.$t('gallery.notify.created'))
          } catch (err) {
            console.error(err)
            NotifyService.setErrorMessage(this.$t('gallery.notify.not_created'))
          }
        }
        this.$emit('add-media', this.createdDamMedia)
        this.$emit('close', true)
      } else {
        this.tryCreateDamMediaAgain = true
      }
    },
    async createMediaUsage (media) {
      let mediaUsagePayload
      try {
        mediaUsagePayload = this.getPayloadForMediaUsage(media)
      } catch (error) {
        console.error(error)
        const stack = error.stack?.toString() ?? error.toString()
        NotifyService.setErrorMessage(stack.replace(/\n/g, '<br />'))
        this.failedDamMedia.push(media)
        return Promise.resolve() // 'resolve', not 'reject' as we want to continue to another media
      }
      return CoreApi(60).post('/ImageUsage', mediaUsagePayload)
        .then(response => {
          this.createdDamMedia.push(response.data)
        })
        .catch(error => {
          this.failedDamMedia.push(media)
          console.error(`Posting of '${media.fileName}' to CORE /ImageUsage failed.`)
          console.error(error)
          const { message, stack } = error
          ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /ImageUsage', payload: mediaUsagePayload })
        })
        .finally(() => {
          this.loadingImageProcess = Math.ceil(this.loadingImageProcess) === this.loadingImageProcess ? this.loadingImageProcess + 1 : Math.ceil(this.loadingImageProcess)
        })
    },
    close () {
      this.$emit('close')
    },
    removeTitles () {
      this.selectedMedia.forEach(media => {
        media.imageCaption = ''
      })
    },
    setPredeterminedTitles () {
      this.selectedMedia.forEach(media => {
        media.imageCaption = this.defaultImageCaption
        media.imageAltText = this.defaultImageAltText
        media.imageAttribution = this.defaultImageAttribution
        media.keywords = this.defaultImageKeywords
      })
      this.forceInputsUpdate += 1
    },
    toggleGallery () {
      this.showGallery = !this.showGallery
    },
    addTag (tag) {
      this.gallery.expanded.tags.push(tag)
      this.searchedTag = ''
    },
    findTag (query) {
      this.$store.dispatch('tag/fetchByTitle', { query, view: 'gallery' })
        .then(() => {
          this.tags = this.$store.getters['tag/tagsWithGalleryCount']
          this.searchedTag = query
        })
    }
  },
  created () {
    this.setCropperWidth(800)
  }
}
</script>

<style scoped lang="scss">
  .btn-green--disabled, .btn-green--disabled:hover {
    color: lighten(#465674, 20%);
    background-color: lighten(#D1DBE4, 5%);
    cursor: default !important;
    box-shadow: none !important;
    opacity: 1 !important;
  }
  .media-edit-modal {
    &__remove-selected-media {
      position: absolute;
      top: rem(-13px);
      right: rem(15px);
      background: #fc4b6c;
      color: #fff;
      width: 2.1875rem;
      height: 2.1875rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 200ms;
      transform: scale(1);
      border-radius: rem(8px);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .check-image-warning .alert-warning {
    font-size: 12px;
  }
  .gallery {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border: 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  .failed-files {
    margin-bottom: 1rem;
    &__label {
      color: red;
    }
  }
</style>
